<template>
  <div>
    <vs-popup class="sm:popup-w-50" title="Edit Item Progress Pekerjaan" :active="isActive" v-on:update:active="emitModalIsActive">
      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full -mt-1.5">
            <label class="ml-1 text-xs opacity-75 -mt-1">Proyek *</label>
            <vx-input-group>
              <vs-input placeholder="Pilih COA" :value="data.proyek.nama" @click="isModalProyekActive = true" readonly/>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="isModalProyekActive = true"/>
                </div>
              </template>
            </vx-input-group>
          </div>
          <div class="vx-col sm:w-1/2 w-full -mt-1.5">
            <label class="ml-1 text-xs opacity-75 -mt-1">COA *</label>
            <vx-input-group>
              <vs-input placeholder="Pilih COA" :value="data.coa.nama" @click="isModalCoaActive = true" readonly/>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="isModalCoaActive = true"/>
                </div>
              </template>
            </vx-input-group>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Group 1 *</label>
            <v-select :options="group1s"  @input="onChangeGroup1" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_item_group1"/>
          </div>
          <div class="vx-col sm:w-1/2 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Group 2</label>
            <v-select :options="group2s" @input="onChangeGroup2" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_item_group2"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Group 3</label>
            <v-select :options="group3s" label="nama" @input="onChangeGroup3" :reduce="item => item.id" :clearable="false" v-model="data.id_item_group3"/>
          </div>
          <div class="vx-col sm:w-1/2 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Group 4</label>
            <v-select :options="group4s" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_item_group4"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" label-placeholder="Kode - Generate Otomatis" v-model="data.kode"/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" label-placeholder="Nama *" v-model="data.nama"/>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

      <!--modals-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih Data Proyek"
                :active="isModalProyekActive"
                v-on:update:active="isModalProyekActive = $event">
        <Proyek :selectable="true" @selected="onSelectedModalProyek"/>
      </vs-popup>
      <vs-popup class="sm:popup-w-50 popup-content-no-padding"
                title="Pilih Data COA"
                :active="isModalCoaActive"
                v-on:update:active="isModalCoaActive = $event">
        <Coa :selectable="true" :externalFilter="filterCoa" @selected="onSelectedModalCoa"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import ItemProgressPekerjaanRepository from '@/repositories/master/item-progress-pekerjaan-repository'
import ItemGroup1Repository from '@/repositories/master/item-group1-repository'
import ItemGroup2Repository from '@/repositories/master/item-group2-repository'
import ItemGroup3Repository from '@/repositories/master/item-group3-repository'
import ItemGroup4Repository from '@/repositories/master/item-group4-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import Proyek from '@/views/pages/master/proyek/Proyek'
import Coa from '@/views/pages/master/coa/Coa'
import vSelect from 'vue-select'
import _ from 'lodash'

export default {
  name: 'ItemProgressPekerjaanEdit',
  props: ['isActive', 'item'],
  components: {
    Proyek,
    Coa,
    ValidationErrors,
    'v-select': vSelect
  },
  mounted () {
    this.getAllGroup1()
  },
  watch: {
    isActive (value) {
      if (value === true) {
        this.data = Object.assign({}, this.item)
        this.data.id_coa = this.item.coa.id
        this.data.id_proyek = this.item.proyek.id
        this.getItemGroup2ById()
        this.getItemGroup3ById()
        this.getItemGroup4ById()
      }
    }
  },
  data () {
    return {
      isModalCoaActive: false,
      isModalProyekActive: false,
      isLoading: false,
      errors: null,
      data: {
        coa: {},
        proyek: {},
        id_item_group1: null,
        id_item_group2: null,
        id_item_group3: null,
        id_item_group4: null
      },
      group1s: [],
      group2s: [],
      group3s: [],
      group4s: []
    }
  },
  computed: {
    filterCoa () {
      return {
        tipe: 'DETAIL'
      }
    }
  },
  methods: {
    getAllGroup1 () {
      ItemGroup1Repository.getAll()
        .then(response => {
          this.group1s = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    onChangeGroup1 () {
      this.group2s = []
      this.group3s = []
      this.group4s = []
      this.data.id_item_group2 = null
      this.data.id_item_group3 = null
      this.data.id_item_group4 = null
      this.getItemGroup2ById()
    },

    onChangeGroup2 () {
      this.group3s = []
      this.group4s = []
      this.data.id_item_group3 = null
      this.data.id_item_group4 = null
      this.getItemGroup3ById()
    },

    onChangeGroup3 () {
      this.group4s = []
      this.data.id_item_group4 = null
      this.getItemGroup4ById()
    },

    getItemGroup2ById () {
      const idItemGroup1 = this.data.id_item_group1
      ItemGroup2Repository.getItemGroup2ById(idItemGroup1)
        .then(response => {
          this.group2s = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getItemGroup3ById () {
      const idItemGroup2 = this.data.id_item_group2
      ItemGroup3Repository.getItemGroup3ById(idItemGroup2)
        .then(response => {
          this.group3s = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getItemGroup4ById () {
      const idItemGroup3 = this.data.id_item_group3
      ItemGroup4Repository.getItemGroup4ById(idItemGroup3)
        .then(response => {
          this.group4s = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    onSelectedModalCoa (item) {
      this.data.coa = item
      this.data.id_coa = item.id
      this.isModalCoaActive = false
    },

    onSelectedModalProyek (item) {
      this.data.proyek = item
      this.data.id_proyek = item.id
      this.isModalProyekActive = false
    },

    save () {
      this.errors = null
      this.isLoading = true

      ItemProgressPekerjaanRepository.update(this.data)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['group1s', 'group2s', 'group3s', 'group4s'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
